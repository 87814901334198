// src/components/Hero.js
import React from 'react';
import { Container, Button } from 'react-bootstrap';
import './Hero.css'; // İsteğe bağlı ek stil dosyası

function Hero() {
  return (
    <div className="hero-section d-flex align-items-center" id="download">
      <Container className="text-center text-white">
        <h1>Güvenli ve Hızlı Tünel Sistemi</h1>
        <p>İnternet özgürlüğünüzü koruyun.</p>
        <a href={`${process.env.PUBLIC_URL}/assets/Escapelag-Demo.exe`} download>
          <Button variant="primary" size="lg" > Demoyu İndir</Button>
        </a>
      </Container>
    </div>
  );
}

export default Hero;
