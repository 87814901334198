// src/components/Footer.js
import React from 'react';
import { Container } from 'react-bootstrap';

function Footer() {
  return (
    <footer className="bg-dark text-white py-3">
      <Container className="text-center">
        &copy; {new Date().getFullYear()} VPN Hizmeti. Tüm hakları saklıdır.
      </Container>
    </footer>
  );
}

export default Footer;
