// src/components/Features.js
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

function Features() {
  const featureList = [
    {
      title: 'Güvenli Bağlantı',
      description: 'Verilerinizi korur ve gizliliğinizi sağlar.',
      icon: '🔒',
    },
    {
      title: 'Hızlı Sunucular',
      description: 'Yüksek hızda internet erişimi sunar.',
      icon: '⚡',
    },
    {
      title: 'Kolay Kullanım',
      description: 'Kullanıcı dostu arayüz ile kolayca bağlanın.',
      icon: '🖥️',
    },
  ];

  return (
    <Container id="features" className="py-5">
      <h2 className="text-center mb-4 text-white">Özelliklerimiz</h2>
      <Row>
        {featureList.map((feature, index) => (
          <Col md={4} key={index} className="mb-4">
            <Card className="h-100 text-center bg-dark text-white border-secondary">
              <Card.Body>
                <div style={{ fontSize: '3rem' }}>{feature.icon}</div>
                <Card.Title className="mt-3">{feature.title}</Card.Title>
                <Card.Text>{feature.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Features;
